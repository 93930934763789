<template>
  <div>
    <h1>Ответы по рекламе</h1>
    <b-form-radio-group
      class="mt-4"
      v-model="filterParams.status"
      :options="statusMap"
      button-variant="outline-default"
      size="md"
      name="radio-btn-outline"
      buttons
    ></b-form-radio-group>
    <div v-if="!empty">
      <div v-if="!loading.ordersAnswersTable" class="hovering__pad mt-4">
        <b-input-group class="p-4">
          <b-form-input
            placeholder="Поиск по блогерам"
            v-model="filterParams.advertiser_instaname"
            type="text"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="updateData" variant="outline-secondary"
              ><b-icon icon="search"
            /></b-button>
          </b-input-group-append>
        </b-input-group>
        <div>
          <default-mobile-table
            stripped
            bordered
            blockrender
            :item="order"
            :fields="fields"
            v-for="order in orders.results"
            :key="order.key"
            ><template #cell(accepted_at)-custom="data"
              ><p class="text-right gray-text fs-12 w-100">
                {{ data.value }}
              </p></template
            >
            <template #cell(advertiser_instaname)-custom="data"
              ><div>
                <b-button variant="link"
                  ><img
                    v-if="data.item.status === 'AC'"
                    src="@main/assets/img/svg/green-check.svg"
                    class="d-inline-block mr-1"
                  />{{ data.value }}</b-button
                >
              </div></template
            ><template #cell(format)-custom="data"
              ><div class="mt-2" v-if="data.value">
                <span class="fw-500">{{ getPrType(data.value) }}</span> —
                {{ data.value.uPrice.toLocaleString().replace(',', ' ') }} ₽<br />{{
                  data.value.target
                }}
                <p class="mt-2">{{ data.value.content }}</p>
              </div> </template
            ><template></template
            ><template #cell(left_bells)-custom="data">
              <div v-if="data.item.status == 'AC'">
                <div
                  class="w-100 d-flex flex-row-reverse flex-xsm-row justify-content-between align-items-start flex-wrap"
                  v-if="data.item.format"
                >
                  <div v-if="data.item.code" class="mt-3 mr-sxm-3 w-100 w-sxm-auto">
                    <b-button
                      :disabled="isBellCooldown(data.item) || data.item.left_bells <= 0"
                      @click="bellBloggerWindow(data.item)"
                      class="w-100"
                      variant="outline-default"
                      >Напомнить блогеру</b-button
                    >
                    <p v-if="isBellCooldown(data.item)" class="gray-text fs-12 text-center">
                      Следующая попытка {{ getBellCooldownDate(data.item).format('DD MMM mm:hh') }}
                    </p>
                    <p class="gray-text fs-12 text-center">
                      Осталось {{ data.item.left_bells }}
                      {{ literal.declension(data.item.left_bells, 'попыт', true) }}
                    </p>
                  </div>
                  <b-button
                    @click="writeBloggerWindow(data.item)"
                    variant="yellow"
                    style="
                       {
                        'width:100%!important':!data.item.code ;
                      }
                    "
                    class="mt-3 w-100 w-sxm-auto"
                    >Написать блогеру</b-button
                  >
                </div>
                <b-button
                  v-else
                  @click="chooseFormat(data.item)"
                  variant="yellow"
                  class="mt-3 w-100"
                  >Выбрать формат рекламы</b-button
                >
              </div>
              <div
                class="gray-text fs-12 mt-4"
                v-else-if="data.item.status === 'NEW' && getOrderBurnDate(data.item) > 0"
              >
                Заявка ещё на рассмотрении {{ getOrderBurnDate(data.item) }}
                {{ literal.declension(getOrderBurnDate(data.item), 'дн') }}
              </div>
              <!--div class="mt-4" v-else-if="data.item.status === 'CL'">
                <b-button @click="resendOrder(data.item)" variant="outline-default" class="w-100"
                  >Отправить заявку повторно</b-button
                >
              </div-->
            </template></default-mobile-table
          >
        </div>
      </div>
      <Loader v-else></Loader>
    </div>
    <div v-else>
      <div
        class="hovering__pad p-4 mt-4 d-flex flex-column justify-content-between align-items-center"
      >
        <div
          style="border: 3px solid lightgray; width: 78px; height: 78px; border-radius: 50%"
          :class="statusMap.filter((val) => val.value === filterParams.status)[0].iconClass"
          class="mt-2 mb-4"
        ></div>
        <p class="text-center fs-16">
          {{ statusMap.filter((val) => val.value === filterParams.status)[0].description }}
        </p>
        <b-button href="/reviews/" class="mt-4" target="_blank" variant="yellow"
          >Найти блогеров для рекламы</b-button
        >
      </div>
    </div>

    <div class="d-flex flex-row justify-content-center">
      <Pagination
        v-if="orders && orders.total_pages > 1"
        :current="filterParams.page"
        :total="orders.total_pages"
        @page-change="pageChangeHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import promobot from '@main/api/promobot';
import Loader from '@main/components/Loader.vue';
import wrappers from '@main/func/wrappers';
import WriteBlogger from './WriteBlogger.vue';
import ChangeCustomer from './ChangeCustomer.vue';
import Pagination from '../Pagination.vue';
import DefaultMobileTable from '../reusable/tables/DefaultMobileTable.vue';

export default {
  components: { DefaultMobileTable, Pagination, Loader },
  data: () => ({
    statusMap: [
      {
        text: 'Одобрено на рекламу',
        value: 'AC',
        description:
          'Здесь вы сможете посмотреть одобренные заявки на рекламу, когда сделаете свой первый заказ.',
        iconClass: 'gray-tick',
      },
      {
        text: 'На рассмотрении',
        value: 'NEW',
        description:
          'Здесь вы сможете посмотреть заявки, которые еще находятся у блогера на рассмотрении.',
        iconClass: 'gray-corner',
      },
      {
        text: 'Отказано в рекламе',
        value: 'CL',
        description:
          'Здесь вы сможете посмотреть заявки, которые блогеры не приняли на рекламу и узнать причину отказа.',
        iconClass: 'gray-cross',
      },
    ],
    orders: null,
    filterParams: {
      page: 1,
      page_size: 25,
      status: 'AC',
      advertiser_instaname: '',
      for_user: true,
    },
    empty: false,
  }),
  methods: {
    ...mapActions(['updateUserCart']),
    ...mapMutations('notifications', ['setWindow', 'showMessage']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    async chooseFormat(order) {
      this.toggleStatisticCardModal({
        open: true,
        context: {
          instaname: order.advertiser_instaname,
          attrs: {
            prTypeClickHandler: async (prType) => {
              await promobot.orders.setFormat(order.id, prType.id);
              this.toggleStatisticCardModal({ open: false, context: { istaname: null } });
              await this.updateData();
            },
          },
        },
      });
      setTimeout(() => {
        document.getElementById('blogger-nopr').scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    },
    async pageChangeHandler(page) {
      this.setFilterParams({ page });
      await this.updateData();
    },
    async updateData(auto) {
      await wrappers.triggersLoading(
        async () => {
          this.empty = false;
          this.orders = await promobot.orders.list(this.filterParams);
          if (!this.filterParams.advertiser_instaname && this.orders.results.length <= 0) {
            this.empty = true;
          }
        },
        'ordersAnswersTable',
        auto
      );
    },
    async generateCode(pk) {
      await promobot.orders.generateCode(pk);
      await this.updateData();
    },
    async sendBell(order) {
      await promobot.orders.sendBell(order.id);
      this.setWindow(null);
      this.showMessage({
        title: `Напоминание блогеру ${order.advertiser_instaname} успешно отправлено!`,
        icon: 2,
      });
    },

    async resendOrder(order) {
      if (order.format) {
        await this.updateUserCart({
          ...this.userCart,
          formats: this.userCart.formats.concat(order.format.id),
        });
      } else if (order.advertiser_instaname) {
        await this.updateUserCart({
          ...this.userCart,
          reviews: this.userCart.reviews.concat(order.review.id),
        });
      }
    },
    setFilterParams(val) {
      this.$set(this, 'filterParams', { ...this.filterParams, ...val });
    },
    getPrType(prType) {
      if (!prType) {
        return 'Любой';
      }
      const { choices } = this.options.pr_types.child.children.kind;
      const values = choices.filter((val) => val.value === prType.kind);
      let namestring = values[0].display_name;
      if ((values[0].value === '1' || values[0].value === '4') && prType.duration) {
        namestring = `${namestring} ${prType.duration ? prType.duration : ''} сек.`;
      }
      return namestring;
    },
    getBellCooldownDate(order) {
      moment.locale('ru');
      return moment(order.last_bell_datetime).add(1, 'd');
    },
    isBellCooldown(order) {
      if (order.last_bell_datetime) {
        return this.getBellCooldownDate(order) > moment();
      }
    },
    getOrderBurnDate(order) {
      moment.locale('ru');
      return moment().diff(moment(order.user_confirm_at).add(3, 'd'), 'days') || 1;
    },
    writeBloggerWindow(order) {
      this.setWindow({
        backMode: true,
        contentClass: 'overflow-auto pb-4',
        contentComponent: WriteBlogger,
        titleAttrs: {
          style: 'border-bottom: 1px solid #d5dfe4; font-size: 18px;',
          class: 'px-4 pb-3 pt-3',
        },
        ccAttrs: { order },
        title: 'Скопировать и написать блогеру',
        ccWrapperAttrs: { class: 'px-4 pb-4' },
        footer: {
          attrs: { style: 'justify-content: left !important', class: 'p-0 m-0' },
        },
        buttons: [
          {
            text: 'Скопировать',
            attrs: { class: 'mt-2', variant: 'outline-default' },
            handler: async (cc) => {
              cc.copyText();
              await this.generateCode(order.id);
            },
          },
          {
            text: 'Написать блогеру',
            attrs: {
              class: 'mt-2',
              variant: 'yellow',
            },
            handler: async (cc) => {
              window.open(`https://instagram.com/${order.advertiser_instaname}`, '_blank');
              await this.generateCode(order.id);
            },
          },
        ],
        buttonClass: 'mt-2',
      });
    },
    bellBloggerWindow(order) {
      this.setWindow({
        backMode: true,
        titleAttrs: {
          style: 'border-bottom: 1px solid #d5dfe4; font-size: 18px;',
          class: 'px-4 pb-3 pt-3',
        },
        title: 'Проверьте себя',
        html: `Прежде, чем мы отправим напоминание блогеру ${order.advertiser_instaname}, убедитесь, что вы:

Ранее отправили этому блогеру в директ сообщение с заказом.

`,
        buttons: [
          {
            text: 'Не отправлял(-а)',
            attrs: { variant: 'outline-default', class: 'ml-0' },
            handler: () => {
              this.writeBloggerWindow(order);
            },
          },
          {
            text: 'Отправлял(-а)',
            attrs: { variant: 'outline-default' },
            handler: () => {
              this.setWindow({
                backMode: true,
                titleAttrs: {
                  style: 'border-bottom: 1px solid #d5dfe4; font-size: 18px;',
                  class: 'px-4 pb-3 pt-3',
                },
                title: 'Проверьте себя',
                textBlockAttrs: {
                  class: 'px-4',
                },
                contentClass: 'px-0',
                html: `Вы отправили сообщение в директ блогеру с аккаунта <a target="_blank" href="https://instagram.com/${order.instaname}">${order.instaname}</a>?`,
                buttons: [
                  {
                    text: 'Нет, с другого',
                    attrs: { variant: 'outline-default', class: 'ml-0' },
                    handler: () => {
                      this.setWindow({
                        backMode: true,
                        ccWrapperAttrs: { class: 'px-4 pb-4' },
                        titleAttrs: {
                          style: 'border-bottom: 1px solid #d5dfe4; font-size: 18px;',
                          class: 'px-4 pb-3 pt-3',
                        },
                        contentClass: 'px-0',

                        title: 'Проверьте себя',
                        contentComponent: ChangeCustomer,
                        footer: { attrs: { style: 'justify-content: start !important' } },
                        buttons: [
                          {
                            text: 'Отправить',
                            attrs: { variant: 'outline-default', class: 'mt-0' },
                            handler: async (cc) => {
                              await promobot.orders.partial_update(
                                { ...order, instaname: cc.instaname },
                                { for_user: true }
                              );
                              await this.sendBell(order);
                              this.updateData();
                            },
                          },
                        ],
                      });
                    },
                  },
                  {
                    text: 'Да, с этого',
                    attrs: { variant: 'outline-default' },
                    handler: async () => {
                      await this.sendBell(order);
                    },
                  },
                ],
              });
            },
          },
        ],
        buttonClass: 'ml-0',
        footer: {
          attrs: { style: 'align-items: flex-start' },
        },
        contentClass: 'px-0',
        textBlockAttrs: {
          class: 'px-4',
        },
        wrapClass: '',
      });
    },
  },
  watch: {
    'filterParams.status': {
      async handler() {
        await this.updateData();
      },
    },
    selectedOrder(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapState(['bloggerOptions', 'loading']),
    fields() {
      return [
        {
          key: 'accepted_at',
          label: 'Дата',
          type: 'other',
          formatter(value, key, item) {
            let date;
            let text;
            if (item.status === 'NEW') {
              date = item.user_confirm_at;
              text = 'Отправлена: ';
            } else if (item.accepted_at && item.status === 'AC') {
              date = value;
              text = 'Одобрена:';
            } else {
              text = 'Отклонена:';
              date = value;
            }
            return `${text} ${moment(date).format('DD.MM.YYYY')}`;
          },
        },
        {
          key: 'advertiser_instaname',
          type: 'other',
        },
        {
          key: 'format',
          type: 'other',
        },
        {
          key: 'left_bells',
          type: 'other',
        },
      ];
    },
    options() {
      return this.bloggerOptions.actions.POST;
    },
  },
  async mounted() {
    await this.updateData();
    this.updateInterval = setInterval(async () => {
      await this.updateData(true);
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-group {
  flex-wrap: wrap;
}
::v-deep .btn-group > label {
  margin-top: 10px;
  border-radius: 5px !important;
  max-width: 170px;
}

::v-deep .btn-group > label:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 350px) {
  ::v-deep .btn-group > label {
    max-width: none !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 350px) {
  ::v-deep .btn-group {
    justify-content-between: !important;
  }
}
</style>
