<template>
  <div>
    <p class="mb-4">
      Если вы отправили сообщение блогеру в директ с другого аккаунта, то укажите его в поле ниже:
    </p>
    <b-form-input placeholder="Введите ник" v-model="instaname" />
  </div>
</template>

<script>
export default {
  data: () => ({
    instaname: null,
  }),
};
</script>
