<template>
  <div>
    <p>
      Скопируйте сообщение ниже, нажмите на кнопку «Написать блогеру» и отправьте в директ аккаунта
      это сообщение.
    </p>
    <b-form-textarea
      style="min-height: 300px; width: 70%"
      class="mt-4"
      v-model="text"
      disabled
    ></b-form-textarea>
  </div>
</template>
<script>
export default {
  props: { order: Object },
  computed: {
    text() {
      let text;
      if (this.order.format) {
        text = `Здравствуйте. Хочу заказать ${this.order.format.target} + ваши макеты в аккаунте @${this.order.advertiser_instaname} по цене ${this.order.format.uPrice} рублей. Мой аккаунт @${this.order.instaname}.

Тезисы:
${this.order.description}`;
      } else {
        text = `Здравствуйте. Хочу заказать рекламу в аккаунте @${this.order.advertiser_instaname}. Мой аккаунт @${this.order.instaname}.

Тезисы:
${this.order.description}`;
      }
      return text;
    },
  },
  methods: {
    copyText() {
      this.$clipboard(this.text);
    },
  },
};
</script>
